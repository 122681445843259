import React from "react"
import {Section} from "./Section"
import classNames from "classnames"

export function ContentElementWrapper({nodeType = "root", className = "", innerClassName = "", background = "default", containerWidth, containerProps = {}, children, as, ...props}) {
	className = classNames("content-element", className)
	const backgroundClassName = classNames(`background-${background}`, {
		"has-background": background !== "default",
	})
	if (nodeType === "root") {
		return <Section as={as} className={classNames(backgroundClassName, className)} containerWidth={containerWidth} containerProps={containerProps} innerClassName={classNames(innerClassName)} children={children} {...props} />
	}
	return <div className={classNames("nested-section", backgroundClassName, className)} {...props}>
		<div className={classNames("nested-section-inner", innerClassName)} children={children} />
	</div>
}
