import React from "react"

export function NotificationMessage({color = "red", children}) {
	return (
		<>
			<div className={`bg-notification-${color} overflow-hidden mb-default`}>
				<div className={`border-l-4 border-notification-${color} text-notification-${color} px-4 py-6 text-left`}>{children}</div>
			</div>
		</>
	)
}
