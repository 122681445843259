import React from "react"
import {Container, ContentElementWrapper, Section} from "../layout"
import {LinkComponent} from "../components"
import {TeaserDefault} from "../components/teaser"
import classNames from "classnames"

export function BlogPostList({nodeType, posts, debug}) {
	return (
		<ContentElementWrapper nodeType={nodeType} background={"white"} containerWidth="none">
			<Container containerWidth="wide" className="" classNameInner="grid grid-cols-1 md:grid-cols-3 gap-default mb-default">
				{posts.map(({link, teaserTitle, date, teaserImage}, i) => {
					return (
						<ContentElementWrapper key={`${i}${link.href}`} nodeType="child" className={classNames("flex", {"md:col-span-3": i === 0})} innerClassName={"flex flex-1"} background="dark-grey">
							<TeaserDefault teaserImage={teaserImage} teaserType="BackgroundImageShadow" style={{minHeight: 450}}>
								<div />
								<div>
									<LinkComponent link={link} className="!no-underline">
										<p className="!mb-0">{date}</p>
										<h4 className="is-h4">
											{teaserTitle}
										</h4>
									</LinkComponent>
								</div>
							</TeaserDefault>
						</ContentElementWrapper>
					)
				})}
			</Container>
		</ContentElementWrapper>
	)
}
