import React from "react"
import classNames from "classnames"
import {useField} from "formik"
import {NotificationMessage} from "../NotificationMessage"

export function FormFieldWrapper({name, label, labelRight, id, description, children}) {
	const [, {touched, error}] = useField(name)
	return (
		<div className="mb-default text-left">
			{label || labelRight ? <div className="flex justify-between">
				{label ? <label htmlFor={id} className="block text-sm text-black">
					{label}
				</label> : null}
				{labelRight ? <span className="block text-sm text-black">
					{labelRight}
				</span> : null}
			</div> : null}
			<div className={classNames(label || labelRight ? "mt-1" : null, "space-y-default/2")}>
				{children}
				{touched && error ? <NotificationMessage {...error} /> : null}
			</div>
			{description ? <p className="mt-2 text-sm text-black" id="email-description">
				{description}
			</p> : null}
		</div>
	)
}
