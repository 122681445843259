import React from "react"
import {ContentElementWrapper} from "../layout"
import $ from "jquery"
import styles from "./Video.module.scss"
import classNames from "classnames"

export function Video({nodeType, videoUrl, videoThumbnail}) {
	const onClick = React.useCallback((e) => {
		e.preventDefault()
		$.magnificPopup.open({
			items: {
				src: e.currentTarget.href,
			},
			type: "iframe",
		})
	}, [])
	return (
		<ContentElementWrapper {...{nodeType, className: "flex", innerClassName: "flex flex-1"}}>
			{videoUrl ? <a onClick={onClick} href={videoUrl} target="_blank" className={classNames(styles.video, "hover-overlay-container")} style={{minHeight: 350}}>
				{videoThumbnail ? <img className="absolute inset-0 h-full w-full object-cover" src={videoThumbnail.url} alt="" /> : null}
				<div className="hover-overlay" />
				{/*<div className="absolute inset-0 bg-red z-10">THIS IS A TEST</div>*/}
				<div className={styles.button}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
					</svg>
				</div>
				{/*<div className="rounded-default absolute inset-x-0 bottom-0 bg-gradient-to-t from-black opacity-80 h-24 lg:h-32" />*/}
				{/*<div className="absolute bottom-0 inset-x-0 p-default text-white overflow-hidden">*/}
				{/*	<div className="child-content-element-container">{children}</div>*/}
				{/*</div>*/}
			</a> : null}
		</ContentElementWrapper>
	)
}
