import React from "react"
import {Link} from "react-location"
import {useSiteConfig} from "../state"
import {ContentElementWrapper} from "./ContentElementWrapper"


function FooterLink({link, title: menuTitle, newTab, type}) {
	if (type === "internal") {
		return <Link className="text-base text-white no-underline hover:underline" target={newTab ? "_blank" : undefined} to={link}>{menuTitle}</Link>
	}
	return <a className="text-base text-white no-underline hover:underline" target={newTab ? "_blank" : undefined} href={link}>{menuTitle}</a>
}


export function Footer() {
	const {siteConfig} = useSiteConfig()
	return (
		<ContentElementWrapper as="footer" containerWidth="wide" background="green">
			{/*<footer className="section background-green">*/}
			{/*	<div className="section-inner">*/}
			{/*		<div className="layout-container">*/}
			{/*			<div className="layout-container-inner">*/}
			<div className="grid grid-cols-12 gap-4">
				<div className="hidden lg:block lg:col-span-4">
					{/*<Link to={`/`} className="flex-none w-48 h-16 flex items-center">*/}
					<div className="w-48">
						<img className="block w-full" src="/images/logo-white.svg" alt={siteConfig.title} />
					</div>
				</div>

				<div className="col-span-12 lg:col-span-8 text-white">
					<div className="md:grid md:grid-cols-12 md:gap-4">
						{siteConfig && siteConfig.footerLinks && siteConfig.footerLinks.length ? siteConfig.footerLinks.map((group, i) => {
							return (
								<div key={i} className={`md:col-span-3 ${i < 3 ? "mb-8 md:mb-0" : ""}`}>
									<h4 className="text-xl mb-default">{group.title}</h4>
									{/*<h3 className="text-sm font-bold text-grey-darker">{group.title}</h3>*/}
									<ul role="list">
										{group.links.map((item, i) => (
											<li key={`${item.link}${i}`}>
												<FooterLink {...item} />
											</li>
										))}
									</ul>
								</div>
							)
						}) : null}
					</div>
				</div>
			</div>
			{/*</div>*/}
			{/*</div>*/}
			{/*</div>*/}
			{/*</footer>*/}

			{/*<div className="section-inner background-green-dark rounded-default" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, paddingTop: 0, paddingBottom: 0}}>*/}
			{/*	<div className="py-4">*/}
			{/*		<div className="container">*/}
			{/*			<div className="container-inner">*/}
			{/*				<div className="flex flex-col sm:flex-row gap-default justify-between">*/}
			{/*					<p className="text-yellow-light uppercase">{siteConfig.footerBottomText}</p>*/}
			{/*					<ul className="flex flex-row">*/}
			{/*						{siteConfig.socialLinks ? Object.entries(siteConfig.socialLinks).map(([key, linkStr], i) => {*/}
			{/*							return (*/}
			{/*								<li key={key} className="mr-6 sm:mr-0 sm:ml-6">*/}
			{/*									<a href={linkStr} target={"_blank"}>*/}
			{/*										{React.createElement(socialIcons[key], {className: "h-6 w-6"})}*/}
			{/*										/!*{linkStr}*!/*/}
			{/*										/!*<img src="/icons/social/fb.svg" alt="" />*!/*/}
			{/*									</a>*/}
			{/*								</li>*/}
			{/*							)*/}
			{/*						}) : null}*/}
			{/*					</ul>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</ContentElementWrapper>

	)
}
