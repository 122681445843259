import React from "react"
import {PageBuilderContentContainer, LinkComponent} from "../components"
import {Container, ContentElementWrapper, PageLayout} from "../layout"


export function BlogPost({page}) {
	const {title, metaTitle, link, absoluteLink, content, contentHtml, type, metaDescription, ...props} = page
	return (
		<PageLayout metaTitle={page.metaTitle} absoluteLink={page.absoluteLink} metaDescription={page.metaDescription}>
			<div className="background-green-light">
				<Container containerWidth="narrow">
					<BlogPostHeader title={page.title} date={page.date} />
					<PageBuilderContentContainer content={content} />
					<BlogPostFooter prevLink={page.prevLink} nextLink={page.nextLink} />
				</Container>
			</div>
		</PageLayout>
	)
}

function BlogPostHeader({date, title}) {
	return (
		<ContentElementWrapper nodeType="root" containerWidth="narrow" className="!pb-0 !-mb-12" innerClassName="!pb-0">
			<div className="text-center">
				<p className="!mb-0">{date}</p>
				<h1 className="is-h1">{title}</h1>
			</div>
		</ContentElementWrapper>
	)
}


function BlogPostFooter({prevLink, nextLink}) {
	return (
		<ContentElementWrapper nodeType="root" containerWidth="narrow" className="!pt-0" innerClassName="!pt-0">
			<div className="flex gap-default flex-col md:flex-row md:justify-between border-t border-b border-grey py-default">
				<div>
					{prevLink ? <LinkComponent link={prevLink} buttonStyle="long-arrow-left">vorheriger Artikel</LinkComponent> : null}
				</div>
				<div>
					{nextLink ? <LinkComponent link={nextLink} buttonStyle="long-arrow-right">nächster Artikel</LinkComponent> : null}
				</div>
			</div>
		</ContentElementWrapper>
	)
}
