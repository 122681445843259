import React from "react"
import {ContentElementWrapper} from "../layout"
import * as teaserTypes from "../components/teaser"
import classNames from "classnames"

export function Teaser({id, data, childNodes, linkedNodes, nodeType, buttonList, children, teaserType, backgroundColor, colSpan, title, ...props}) {
	let type = teaserType
	if (["BackgroundImageFull", "BackgroundImageRight"].includes(teaserType)) {
		type = "Default"
	}
	const TeaserComponent = teaserTypes[`Teaser${type}`] || teaserTypes["TeaserDefault"]
	return (
		<ContentElementWrapper {...{nodeType, className: classNames("flex", {"md:col-span-2": colSpan}), innerClassName: "flex flex-1"}} background={backgroundColor}>
			<TeaserComponent teaserType={teaserType} {...props}>
				<div>
					{title ? <h4 className="is-h4">{title}</h4> : null}
				</div>
				<div className="child-content-element-container">{linkedNodes.Content.children}</div>
			</TeaserComponent>
		</ContentElementWrapper>
	)
}
