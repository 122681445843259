import React from "react"
import {Helmet} from "react-helmet"

export function HeadComponent({metaTitle, absoluteLink, metaDescription}) {
	return (
		<Helmet>
			{/* TODO x-page-id */}
			{/* TODO x-cms-edit-link */}
			{/* TODO extraMeta */}
			<title>{metaTitle}</title>
			<link rel="canonical" href={absoluteLink} />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="de_DE" />
			<meta property="og:title" content={metaTitle} />
			<meta property="twitter:title" content={metaTitle} />
			<meta property="twitter:card" content="summary" />
			{metaDescription ? <meta name="description" content={metaDescription} />: null}
		</Helmet>
	)
}
