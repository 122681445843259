import React from "react"
import {Header, Footer} from "./layout"
import {AppStateContext, AppStateProvider} from "./state"
import axios from "axios"
// import {ScrollDownArrow} from "./components/ScrollDownArrow"
import {PrivacyManagerModal} from "./components/PrivacyManager"
import {queryClient} from "./state"
import {ReactLocation, Router, useMatch} from "react-location"
import {QueryClientProvider} from "react-query"
import * as routeTemplates from "./Pages"
import {Spinner} from "./components/Spinner"
import {CartOverlay} from "./layout/CartOverlay"

const location = new ReactLocation()

const fetchRoute = async (url) => {
	// await new Promise((r) => setTimeout(r, 10000))
	const {data} = await axios.get(url, {params: {json: "1"}})
	return data
}

function PendingPage() {
	return (
		<div className={`pt-16`}>
			<Header />
			<div className="min-h-screen-nav relative">
				<div className="absolute h-6 w-6 -ml-3 -mt-3 left-1/2 top-1/2 text-primary">
					<Spinner className="h-5 w-5" />
				</div>
			</div>
		</div>
	)
}

function RouteComponent() {
	const match = useMatch()
	// TODO use useRoute() to live update when editing through the CMS
	// const {status, data, error, isFetching} = useRoute(match.pathname)
	let routeData = match.data
	const {setCurrentRoute, errorPages} = React.useContext(AppStateContext)
	if (routeData?.error?.code) {
		routeData = errorPages[`${routeData.error.code}`]
	}
	if (!routeData) {
		routeData = errorPages["500"]
	}
	const TemplateComponent = routeTemplates[routeData.template]
	React.useEffect(() => {
		setCurrentRoute(routeData)
		return () => setCurrentRoute(null)
	}, [routeData.url])
	return (
		<div className={`pt-16`}>
			<Header />
			{(() => {
				if (!TemplateComponent) {
					return <span>Error: route template "{routeData.template}" not found</span>
				}
				return (
					<>
						<TemplateComponent {...routeData} />
						<Footer />
					</>
				)
			})()}
			{/*<pre>{JSON.stringify({data}, null, 2)}</pre>*/}
			{/*<Page {...pages.find((({link}) => link === "/404/"))} />*/}
		</div>
	)
}


function App(props) {
	// FIXME we need a error component
	const {siteConfig, initialRoute, privacyManager} = props
	const loader = React.useCallback((loaderMatch) => {
		const url = loaderMatch.pathname
		let data = queryClient.getQueryData(["route", url])
		if (!data) {
			data = queryClient.fetchQuery(["route", url], () => fetchRoute(url))
		}
		return data
	}, [])
	const routes = React.useMemo(() => {
		queryClient.setQueryData(["route", initialRoute.url], initialRoute)
		const _routes = []
		const routeProps = {
			loader,
			element: <RouteComponent />,
			pendingElement: <PendingPage />,
			pendingMs: 1,
			pendingMinMs: 500,
		}
		const routePaths = ["/"]
		let lastPath = ""
		Array(26).fill().map((element, index) => String.fromCharCode("a".charCodeAt(0) + index)).map(char => {
			lastPath = `${lastPath}/:${char}`
			routePaths.push(`${lastPath}/`)
		})
		routePaths.reverse().forEach((path) => {
			_routes.push({
				...routeProps,
				path,
			})
		})
		return _routes
	}, [])
	return (
		<AppStateProvider {...props}>
			<QueryClientProvider client={queryClient}>
				<Router {...{location, routes}} />
				<CartOverlay />
			</QueryClientProvider>
			{/*<PrivacyManagerModal {...privacyManager} />*/}
		</AppStateProvider>
	)
}

export default App
// export default hot(module)(App)
