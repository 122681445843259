import React from "react"
import {ContentElementWrapper} from "../layout"
import {LinkComponent, Typography} from "../components"
import {TeaserDefault, TeaserPortrait} from "../components/teaser"
import {FaqQuestionList} from "../components/FaqQuestionList"

export function FaqArea({nodeType, categories, topQuestions}) {
	return (
		<>
			<ContentElementWrapper {...{nodeType}} background={"grey"}>
				<div className="grid gap-default grid-cols-1 md:grid-cols-2 lg:grid-cols-4 -mt-28">
					{categories.map(category => (
						<TeaserDefault key={category.link} className="flex flex-col background-green">
							<div className="flex-1">
								<h2 className={"is-h6"}>{category.title}</h2>
								<div className="mb-default py-default">
									{category.iconImage ? <img src={category.iconImage.url} alt="" className="w-32 max-w-full" /> : null}
								</div>
							</div>
							<LinkComponent className="w-full" buttonStyle="button-underline" link={{type: "Internal", href: category.link}}>mehr dazu</LinkComponent>
						</TeaserDefault>
					))}
				</div>
			</ContentElementWrapper>
			<ContentElementWrapper {...{nodeType}}>
				<FaqQuestionList questions={topQuestions} />
			</ContentElementWrapper>
		</>
	)
}
