import React from "react"
import {ContentElementWrapper} from "../layout"
import styles from "./ListElement.module.scss"

export function ListElement({nodeType, items}) {
	return (
		<ContentElementWrapper {...{nodeType}}>
			<ul className={styles.list}>
				{items.map((item, i) => (
					<li key={`${i}${item.value}`}>{item.value}</li>
				))}
			</ul>
		</ContentElementWrapper>
	)
}
