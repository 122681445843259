import React from "react"
import {ScrollToTop} from "../components"
import {PrivacyManagerRouteListener} from "../components/PrivacyManager"
import {HeadComponent} from "./HeadComponent"

export function PageLayout({metaTitle, absoluteLink, metaDescription, children}) {
	return (
		<div>
			<ScrollToTop />
			<PrivacyManagerRouteListener />
			<HeadComponent metaTitle={metaTitle} absoluteLink={absoluteLink} metaDescription={metaDescription} />
			{children}
		</div>
	)
}
