import React from "react"
import {Form, useFormik, FormikProvider} from "formik"

export function FormikForm({children, onSubmit, onReset, initialValues, validate, className = "", ...props}) {
	const [globalFormErrors, setGlobalFormErrors] = React.useState({})
	const _onSubmit = React.useCallback((values, formikBag) => {
		setGlobalFormErrors({})
		typeof onSubmit === "function" && onSubmit(values, {...formikBag, setGlobalFormErrors})
	}, [])
	const _onReset = React.useCallback((values, formikBag) => {
		setGlobalFormErrors({})
		typeof onReset === "function" && onReset(values, {...formikBag, setGlobalFormErrors})
	}, [])
	const allProps = {
		...props,
		onSubmit: _onSubmit,
		onReset: _onReset,
		initialValues,
		validate,
	}
	const formikBag = {...useFormik(allProps), globalFormErrors, setGlobalFormErrors}
	React.useImperativeHandle(allProps.innerRef, () => formikBag)
	return (
		<FormikProvider value={formikBag}>
			<Form className={className}>
				{typeof children === "function" ? children(formikBag) : children}
			</Form>
		</FormikProvider>
	)
}
