import React from "react"

export function TeaserPortrait({children, teaserImage, className = "", ...props}) {
	return (
		<>
			<div className={`flex-1 ${className}`}>
				{teaserImage ? <img src={teaserImage.url} alt="" className="w-full mb-default" /> : null}
				<div>
					{children}
				</div>
			</div>
		</>
	)
}
