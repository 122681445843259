import React from "react"
import {useUniqueId} from "../../utility"
import {FormFieldWrapper} from "./FormFieldWrapper"
import {Field, useField} from "formik"

export function FormCheckboxField({
	                              name,
	                              label,
	                              labelDescription,
	                              labelLeft,
	                              labelRight,
	                              id,
	                              description,
	                              borderColor = "grey-light",
	                              // inputClass = "",
	                              disabled,
	                              required,
                              }) {
	id = useUniqueId(id)
	const inputClass = ""
	const [, {touched, error}] = useField(name)
	if (touched && error) {
		borderColor = `notification-${error.color}`
	}
	return (
		<FormFieldWrapper {...{name, label: labelLeft, labelRight, id, description}}>
			<div className="relative flex items-start">
				<div className="flex items-center h-5">
					<Field
						// aria-describedby="comments-description"
						name={name}
						type="checkbox"
						id={id}
						className={`h-5 w-5 text-green border-2 rounded-none focus:ring-0 border-${borderColor} ${inputClass}`}
						style={{boxShadow: "none"}}
						disabled={disabled}
						required={required}
					/>
				</div>
				<div className="ml-3 text-sm">
					<label htmlFor={id} className="font-medium">
						{label}
					</label>
					{labelDescription ? <p id="comments-description" className="text-grey">
						{labelDescription}
					</p> : null}
				</div>
			</div>
		</FormFieldWrapper>
	)
}
