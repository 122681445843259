import React from "react"
import classNames from "classnames"
import {ContentElementWrapper} from "../layout"
import {LinkComponent} from "../components"

export function Hero({children, childNodes, linkedNodes, nodeType, textColor, ...props}) {
	const {backgroundImage} = props
	// const bgPosition = imagePosition === 'right' ? 'left' : 'right':
	const fullClassName = `min-h-screen-nav`

	// bg-cover bg-center
	// style={{backgroundImage: backgroundImage && `url('${backgroundImage.url}')`}}
	// background="image"
	return (
		<ContentElementWrapper
			nodeType={nodeType}
			containerWidth="wide"
			className={`text-${textColor} ${fullClassName} flex justify-center relative bg-no-repeat bg-center bg-cover`}
			style={{backgroundImage: backgroundImage && `url('${backgroundImage.url}')`}}
			innerClassName={`flex`}
			containerProps={{className: "flex", classNameInner: "flex items-end"}}
		>
			{/*<div className={`absolute w-1/2 top-0 right-0 bg-red bg-right-top bg-contain ${fullClassName}`} style={{backgroundImage: backgroundImage && `url('${backgroundImage.url}')`}}></div>*/}
			<div className="flex flex-col-reverse md:flex-row gap-default items-center">
				<div className="md:w-1/2 child-content-element-container">
					{linkedNodes.Content.children}
				</div>
				<div className="md:w-1/2 text-right">
					{/*{contentImage && contentImage.url ? <img src={contentImage.url} alt="" className="w-full" /> : null}*/}
					<LinkComponent buttonStyle="scroll-down" />
				</div>
			</div>
		</ContentElementWrapper>
	)
}
