import React from "react"
import {Disclosure} from "@headlessui/react"
import {AppStateContext, useSiteConfig} from "../state"
import classNames from "classnames"
import {Link} from "react-location"
import {MenuIcon, XIcon, UserIcon} from "@heroicons/react/outline"
import {Helmet} from "react-helmet"
import styles from "./Header.module.scss"
import {LinkComponent} from "../components"
import Icon from "@mdi/react"
import {mdiCart} from "@mdi/js"

const NavLink = Link

export function Header({transparent = false}) {
	// const {pages} = usePages()
	const {siteConfig} = useSiteConfig()
	const {setShowCartOverlay} = React.useContext(AppStateContext)

	const [scrolled, setScrolled] = React.useState(false)
	const [scrolled2, setScrolled2] = React.useState(false)
	const [isOpen, setIsOpen] = React.useState(false)
	const isTransparent = transparent && !scrolled
	const hamburgerOnClick = React.useCallback(() => {
		setIsOpen(val => !val)
	}, [])
	React.useEffect(() => {
		const scroll = (e) => {
			if (window.scrollY > 150) {
				setScrolled2(true)
			} else {
				setScrolled2(false)
			}
			if (window.scrollY > 400) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		}
		// if (transparent) {
		document.addEventListener("scroll", scroll)
		// }
		return () => document.removeEventListener("scroll", scroll)
	}, [])
	const cartOnClick = React.useCallback(() => {
		setShowCartOverlay(true)
	}, [])
	return (
		<>
			<Helmet>
				<body className={isOpen ? "overflow-hidden md:overflow-auto" : ""} />
			</Helmet>
			{/*{isTransparent ? <div className="absolute inset-x-0 top-0 h-32 lg:h-64 bg-gradient-to-b from-black opacity-50" style={{zIndex: 90}} /> : null}*/}
			<nav className={classNames(styles.nav, {
				[styles.scrolled]: !isTransparent || scrolled2,
				[styles.isOpen]: isOpen,
				[styles.isTransparent]: isTransparent,
			})}>
				<div className="layout-container">
					<div className="layout-container-inner">
						{/*<div className="w-full mx-auto max-w-7xl px-4 md:px-8 lg:px-16">*/}
						<div className="flex justify-between">
							<Link to={`/`} className="flex-none w-48 h-16 flex items-center">
								<img className="block w-full" src={isOpen ? "/images/logo-white.svg" : "/images/logo.svg"} alt={siteConfig.title} />
							</Link>
							<div className="flex flex-1 md:h-16 justify-start md:justify-center md:static absolute top-20 bottom-0 inset-x-4">
								<div className={classNames(
									`${isOpen ? "flex" : "hidden"} space-y-2 flex-grow items-start flex-col`,
									"md:flex md:space-x-8 md:space-y-0 md:flex-grow md:flex-row md:items-center md:justify-center",
								)}>
									{siteConfig.headerNavigation.map((page, i) => {
										//activeStyle={{color: isTransparent ? "white" : "black"}}
										const linkProps = {
											className: classNames(styles.link, {
												[styles.isOpen]: isOpen,
												[styles.isTransparent]: isTransparent,
											}),
											key: page.link,
											children: page.menuTitle,
										}
										// FIXME redirector page
										// if (page.type === "RedirectorPage") {
										// 	if (page.redirectionType === "External") {
										// 		linkProps.target = "_blank"
										// 	}
										// 	return (
										// 		<a href={page.link} {...linkProps} />
										// 	)
										// }
										// <NavLink exact activeClassName={styles.isActive} to={page.link} {...linkProps} />
										//activeOptions={{exact: true}}
										return (
											<React.Fragment key={`${page.link}${i}`}>
												{i > 0 ? <span className="text-grey-light hidden md:inline">•</span> : ""}
												<NavLink getActiveProps={() => ({className: styles.isActive})} to={page.link} {...linkProps} />
											</React.Fragment>
										)
									})}
								</div>
							</div>
							<div className="flex-none w-48 h-16 flex items-center justify-end">
								<button className={`flex items-center ${isOpen ? "text-white md:text-black" : `text-black`}`} onClick={cartOnClick}>
									<Icon path={mdiCart} className="w-6 h-6 mr-2" />
									<span className="hidden lg:inline">Warenkorb</span>
								</button>
								<button className={classNames(styles.hamburger, "md:hidden -mr-4", {
									[styles.isOpen]: isOpen,
									[styles.isTransparent]: isTransparent,
								})} onClick={hamburgerOnClick}>
									{/*<span className="sr-only">Open main menu</span>*/}
									{isOpen ? <XIcon className="block h-8 w-8" aria-hidden="true" /> : <MenuIcon className="block h-8 w-8" aria-hidden="true" />}
								</button>
							</div>
						</div>
					</div>
				</div>
				{/*<HeaderInner {...{pages, siteConfig, open, isTransparent, setIsOpen}} />*/}
			</nav>
		</>
	)
}
