import React from "react"
import {ContentElementWrapper} from "../layout"
import {LinkComponent} from "../components"
import classNames from "classnames"

export function Buttons(props) {
	const {nodeType, buttons, alignment} = props
	return (
		<ContentElementWrapper {...{nodeType}}>
			<div className={classNames("flex flex-wrap gap-2 items-center", {
				"justify-start": alignment === "left",
				"justify-end": alignment === "right",
				"justify-center": alignment === "center",
			})}>
				{buttons.map((button, i) => (
					// <div key={`${i}${button.link}`}>
					// <span>{JSON.stringify(button)}</span>
					<LinkComponent key={`${i}${button.link}`} link={button.link} buttonStyle={button.style} children={button.title} />
					// </div>
				))}
			</div>
			{/*<pre>{JSON.stringify()}</pre>*/}
			{/*buttonListProp*/}
		</ContentElementWrapper>
	)
}
