import React from "react"
import {Container, ContentElementWrapper, Section} from "../layout"
import {LinkComponent} from "../components"
import {TeaserDefault} from "../components/teaser"
import classNames from "classnames"

export function BlogTeaser({nodeType, posts, title, blogLink, buttonTitle}) {
	return (
		<ContentElementWrapper nodeType={nodeType} background={"grey"} containerWidth="none">
			<Section as="div" className="!pt-0 !pb-default" containerWidth="wide" innerClassName="!py-0" containerProps={{classNameInner: "flex gap-default flex-col xs:flex-row xs:justify-between xs:items-end"}}>
				<h3 className="is-h5 !mb-0">{title}</h3>
			</Section>
			<Container containerWidth="wide" className="" classNameInner="grid grid-cols-1 md:grid-cols-3 gap-default mb-default">

				{posts.map(({link, teaserTitle, date, teaserImage}, i) => {
					return (
						<ContentElementWrapper key={`${i}${link.href}`} nodeType="child" className={classNames("flex", {"md:col-span-2": i === 0})} innerClassName={"flex flex-1"} background="dark-grey">
							<TeaserDefault teaserImage={teaserImage} teaserType="BackgroundImageShadow">
								<div />
								<div>
									<LinkComponent link={link} className="!no-underline">
										<p className="!mb-0">{date}</p>
										<h4 className="is-h4">
											{teaserTitle}
										</h4>
									</LinkComponent>
								</div>
							</TeaserDefault>
						</ContentElementWrapper>
					)
				})}
			</Container>
			{buttonTitle ? <Section as="div" className="!pt-0 !pb-default" containerWidth="wide" innerClassName="!py-0" containerProps={{classNameInner: "flex gap-default flex-col xs:flex-row xs:justify-between xs:items-end"}}>
				<LinkComponent link={blogLink} buttonStyle="underline" className="text-black">{buttonTitle}</LinkComponent>
			</Section> : null}
		</ContentElementWrapper>
	)
}
