import React from "react"
import {Spinner} from "../Spinner"
import {useFormikContext} from "formik"

export function SubmitButton({children, className, buttonStyle = "green-full", disabled, ...props}) {
	const {isSubmitting} = useFormikContext()
	return (
		<FormButton {...props} isLoading={isSubmitting} children={children} className={className} buttonStyle={buttonStyle} disabled={disabled} />
	)
}

export function FormButton({children, isLoading, type, className, buttonStyle = "green-full", disabled, as = "button", onClick, ...props}) {
	return React.createElement(as, {
		...props,
		type,
		className: `${className} cursor-pointer w-full button-${buttonStyle} flex items-center align-bottom`,
		disabled: disabled || isLoading,
		onClick,
		children: (
			<>
				{isLoading ? <Spinner className="h-4 w-4 mr-3" /> : ""}
				<span>{children}</span>
			</>
		),
	})
}
