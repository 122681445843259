import React from "react"
import {useLocation} from "react-location"

export function ScrollToTop({children}) {
	const location = useLocation()
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [JSON.stringify(location.current)])
	return <>{children}</>
}
