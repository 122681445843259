import React from "react"
import {Typography, PageBuilderContentContainer} from "../components"
import {PageLayout, Section} from "../layout"


export function Page({page, url}) {
	const {title, metaTitle, link, absoluteLink, content, contentHtml, type, metaDescription, ...props} = page
	return (
		<PageLayout metaTitle={metaTitle} absoluteLink={absoluteLink} metaDescription={metaDescription}>
			<PageBuilderContentContainer content={content} />
			{contentHtml ? <Section>
				<Typography>
					<div dangerouslySetInnerHTML={{__html: contentHtml}} />
					<div>
						{type === "PrivacyPolicyPage" ? <button className="button-green-full" onClick={() => setPrivacyManagerOpen("edit")}>Einstellungen zu Cookies & Dienste</button> : null}
					</div>
				</Typography>
			</Section> : null}
		</PageLayout>
	)
}
