import React from "react"
import {ContentElementWrapper} from "../layout"
import {TeaserPortrait} from "../components/teaser"
import {Link} from "react-location"

export function ProductTeaser({nodeType, product}) {
	return (
		<ContentElementWrapper {...{nodeType}}>
			<Link to={product.link}>
				<TeaserPortrait teaserImage={product.teaserImage}>
					<h3>{product.teaserTitle}</h3>
					<p><b>{product.priceGrossNice}</b></p>
				</TeaserPortrait>
			</Link>
		</ContentElementWrapper>
	)
}
