import React from "react"
import {NotificationMessage} from "../NotificationMessage"
import {useFormikContext} from "formik"

export function FormikGlobalFormError({name}) {
	const {globalFormErrors} = useFormikContext()
	return (
		<>
			{globalFormErrors.top ? <NotificationMessage {...globalFormErrors.top} /> : null}
		</>
	)
}
