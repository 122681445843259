import React from "react"
import classNames from "classnames"

export function Container({children, elementRef, containerWidth = "narrow", className = "", classNameInner = "", style = {}, styleInner = {}, propsInner = {}, ...props}) {
	return (
		<div style={style} ref={elementRef} className={classNames(className, "layout-container", `layout-container-max-width-${containerWidth}`)} {...props}>
			<div style={styleInner} className={classNames(classNameInner, "layour-container-inner")} {...propsInner}>
				{children}
			</div>
		</div>
	)
	// return (
	// 	<div style={style} className={classNames(className, `element-inner-container w-full relative py-16 sm:py-24 grid grid-cols-1 gap-${gap} ${columnsBreakpoint}:gap-${lgGap} ${columnsBreakpoint}:items-${verticalAlignment}`, {
	// 		"mx-auto max-w-7xl px-4 sm:px-8 md:px-16": maxWidth,
	// 		// 'lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start': twoColumns,
	// 		[`${columnsBreakpoint}:grid-cols-2 ${columnsBreakpoint}:items-start`]: columns > 1,
	// 		[`lg:grid-cols-3`]: columns > 2,
	// 		[`xl:grid-cols-4`]: columns > 3,
	// 		// 'justify-end': !columnsReverse,
	// 	})} {...props}>
	// 		{children}
	// 	</div>
	// )
}
