import {Helmet} from "react-helmet"
// import {MemoryRouter as Router, Switch, Route, Link, NavLink} from "react-router-dom"
import {AppStateContext} from "../state"
import React, {useEffect, Fragment} from "react"
// import {withRouter} from "react-router-dom"
import {Typography} from "./Typography"
import {useLocation} from "react-location"

function CheckboxList({groups, state, setState}) {
	const updateCheckbox = React.useCallback((e) => {
		const checked = e.target.checked
		const id = e.target.dataset.id
		const parentId = e.target.dataset.parent
		const group = groups.find(group => group.id === (parentId ? parentId : id))
		setState(oldState => {
			const newState = JSON.parse(JSON.stringify(oldState))
			newState.ids[id] = checked
			if (!parentId) {
				group.items.forEach(item => newState.ids[item.id] = checked)
			} else {
				let allOthersChecked = true
				group.items.forEach(item => {
					if (item.id !== id) {
						allOthersChecked = allOthersChecked && newState.ids[item.id]
					}
				})
				// const otherItems = group.items.filter(item => item.id !== id)
				if (checked) {
					if (allOthersChecked) {
						newState.ids[parentId] = true
					}
				} else {
					newState.ids[parentId] = false
				}
			}
			return newState
		})
	}, [])
	return (
		<div className="divide-y divide-grey-light mb-4">
			{groups ? groups.map(group => {
				if (!group.items.length) {
					return null
				}
				return (
					<React.Fragment key={group.id}>
						<div className={"py-2"}>
							<Checkbox title={group.title} description={group.subTitle} id={group.id} {...{state, setState}} updateCheckbox={updateCheckbox} readOnly={group.necessary} />
							{/*<div className="pl-4 divide-y divide-grey-light"></div>*/}
						</div>
						{group.items.map(item => {
							const moreLink = item.content ? (
								<Link to={`/privacy-manager/details/${item.id}`} rel="nofollow" className="ml-2 text-grey inline-flex items-center hover:text-black">
									{/*x="0px" y="0px" width="45.999px" height="45.999px"*/}
									<svg fill="currentColor" className="h-3 inline pr-1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 45.999 45.999" style={{enableBackground: "new 0 0 45.999 45.999"}} xmlSpace="preserve">
										<path d="M39.264,6.736c-8.982-8.981-23.545-8.982-32.528,0c-8.982,8.982-8.981,23.545,0,32.528c8.982,8.98,23.545,8.981,32.528,0 C48.245,30.281,48.244,15.719,39.264,6.736z M25.999,33c0,1.657-1.343,3-3,3s-3-1.343-3-3V21c0-1.657,1.343-3,3-3s3,1.343,3,3V33z M22.946,15.872c-1.728,0-2.88-1.224-2.844-2.735c-0.036-1.584,1.116-2.771,2.879-2.771c1.764,0,2.88,1.188,2.917,2.771 C25.897,14.648,24.746,15.872,22.946,15.872z" />
									</svg>
									<span>mehr erfahren</span>
								</Link>
							) : null
							return (
								<div className={"py-2 pl-4"} key={item.id}>
									<Checkbox title={item.title} rightTitle={moreLink} description={item.subTitle} id={item.id} parentId={group.id} {...{
										state,
										setState,
									}} updateCheckbox={updateCheckbox} readOnly={group.necessary} />
								</div>
							)
						})}
					</React.Fragment>
				)
			}) : null}
			{/*<Checkbox title={"A"} description={"B"} id={"test-2"}></Checkbox>*/}
			{/*<Checkbox title={"A"} description={"B"} id={"test-3"}></Checkbox>*/}
			{/*<Checkbox title={"A"} description={"B"} id={"test-4"}></Checkbox>*/}
		</div>
	)
}

function Checkbox({title, rightTitle, description, id, state, parentId, updateCheckbox, readOnly}) {
	const checked = state.ids[id]
	const htmlId = `privacy-manager-${id}`
	//mb-2 pb-2 border-b-2 border-grey-lighter
	return (
		<div className="relative flex items-start">
			<div className="flex items-center pt-1">
				{readOnly ?
					<input type="checkbox" className="opacity-70" id={htmlId} checked={true} readOnly={true} />
					:
					<input type="checkbox" id={htmlId} data-id={id} data-parent={parentId} checked={checked} onChange={updateCheckbox} />
				}
			</div>
			<div className="ml-3 text-sm">
				{/*{title} {id} {parentId}*/}
				<label htmlFor={htmlId} className="text-black">
					{title}
					{/*{readOnly ? "readOnly" : "not readOnly"}*/}
				</label>
				{rightTitle}
				<p className="text-grey">
					{description}
				</p>
			</div>
		</div>
	)
}

function postData(data, onSuccess) {
	fetch("/frontend/v1/privacyManager", {
		method: "post",
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	}).then(res => res.json()).then(data => {
		onSuccess(data)
	})
}

export function PrivacyManagerModal({content, content2, contentImprint, contentPrivacyPolicy, groups, allowedFeatures}) {
	return <div></div>
	// // const props = {content, content2, contentImprint, contentPrivacyPolicy, groups}
	// const {privacyManagerState, setPrivacyManagerState, privacyManagerOpen, setPrivacyManagerOpen} = React.useContext(AppStateContext)
	// const isOpen = privacyManagerOpen !== "closed"
	// const [state, setState] = React.useState(() => JSON.parse(JSON.stringify(privacyManagerState)))
	// window.PrivacyManager.processFeatures(allowedFeatures)
	// if (!groups.length) {
	// 	return null
	// }
	// const applyState = React.useCallback((newState, reload) => {
	// 	if (reload) {
	// 		// some scripts might already be included that are now not allowed anymore
	// 		window.location.reload()
	// 	} else {
	// 		setState(newState)
	// 		setPrivacyManagerState(newState)
	// 		setPrivacyManagerOpen("closed")
	// 	}
	// }, [])
	// const onClickAcceptAll = React.useCallback(() => {
	// 	postData({type: "all"}, (newState) => {
	// 		applyState(newState.state, false)
	// 		window.PrivacyManager.processFeatures(newState.allowedFeatures)
	// 	})
	// }, [])
	// const onClickAcceptFunctional = React.useCallback(() => {
	// 	postData({type: "functional"}, (newState) => {
	// 		applyState(newState.state, privacyManagerOpen === "edit")
	// 		window.PrivacyManager.processFeatures(newState.allowedFeatures)
	// 	})
	// }, [])
	// const onClickAcceptSelected = React.useCallback(() => {
	// 	postData({type: "ids", ids: state.ids}, (newState) => {
	// 		applyState(newState.state, privacyManagerOpen === "edit")
	// 		window.PrivacyManager.processFeatures(newState.allowedFeatures)
	// 	})
	// }, [JSON.stringify(state)])
	// return (
	// 	<>
	// 		<Helmet>
	// 			<body className={isOpen ? "overflow-hidden sm:overflow-hidden" : ""} />
	// 		</Helmet>
	// 		{isOpen ? <div className="fixed inset-0 flex justify-center content-center flex-wrap" style={{zIndex: 300, background: "rgba(0, 0, 0, .5)"}}>
	// 			<Router initialEntries={["/privacy-manager/"]} initialIndex={0}>
	// 				{(() => {
	// 					const backButton = <Link to="/privacy-manager/" rel="nofollow" className="button-green-light-full">zurück</Link>
	// 					const backButtonDetails = <Link to="/privacy-manager/details/" rel="nofollow" className="button-green-light-outline">zurück</Link>
	//
	// 					return (
	// 						<div className="shadow rounded-lg p-4 sm:p-8 bg-white max-w-3xl overflow-auto" style={{maxHeight: "calc(100vh - 40px)", width: "calc(100vw - 40px)"}}>
	// 							<div className="sm:flex sm:justify-between">
	// 								<div className="pb-8">
	// 									<NavLink activeClassName="underline" rel="nofollow" to="/privacy-manager/imprint/">Impressum</NavLink>
	// 									<span className="text-grey-light">{" | "}</span>
	// 									<NavLink activeClassName="underline" rel="nofollow" to="/privacy-manager/privacy-policy/">Datenschutzerklärung</NavLink>
	// 								</div>
	// 								<div className="hidden sm:block">
	// 									<img src="/images/logo.svg" alt="" className="w-32" />
	// 								</div>
	// 							</div>
	// 							<div>
	// 								<Switch>
	// 									{content ? <Route exact path="/privacy-manager/">
	// 										<Typography>
	// 											<div dangerouslySetInnerHTML={{__html: content}} />
	// 										</Typography>
	// 										<div className="sm:flex justify-between">
	// 											<div>
	// 												<Link to="/privacy-manager/details/" rel="nofollow" className="button-green-light-outline mb-2 sm:mb-0">Erweiterte Einstellungen</Link>
	// 											</div>
	// 											<div className="flex justify-between sm:block">
	// 												<button className="button-green-light-outline" onClick={onClickAcceptFunctional}>Nur Erforderliche</button>
	// 												{" "}
	// 												<button className="button-green-light-full" onClick={onClickAcceptAll}>Alles akzeptieren</button>
	// 											</div>
	// 										</div>
	// 									</Route> : null}
	// 									<Route exact path={content ? "/privacy-manager/details/" : "/privacy-manager/"}>
	// 										<Typography>
	// 											<div dangerouslySetInnerHTML={{__html: content2}} />
	// 										</Typography>
	// 										<div>
	// 											<CheckboxList groups={groups} state={state} setState={setState} />
	// 										</div>
	// 										<div className="flex justify-between">
	// 											<div>{content ? backButton : null}</div>
	// 											<div>
	// 												#SilverStripe\MimeValidator\MimeUploadValidator:
	// 												#  MimeTypes:
	// 												#    svg:
	// 												#      - 'image/svg+xml'
	// 												#      - 'image/svg'
	// 												#SilverStripe\Assets\File:
	// 												#  allowed_extensions:
	// 												#    - svg
	// 												<button className="button-green-light-full" onClick={onClickAcceptSelected}>Auswahl akzeptieren</button>
	// 											</div>
	// 										</div>
	// 									</Route>
	// 									{groups.map(group => {
	// 										return group.items.map(item => {
	// 											if (!item.content) {
	// 												return null
	// 											}
	// 											return (
	// 												<Route exact path={`/privacy-manager/details/${item.id}`} key={item.id}>
	// 													<div className="mb-4">{backButtonDetails}</div>
	// 													<h1 className="is-h6" style={{margin: 0}}>{item.title}</h1>
	// 													<p className="text-grey mb-4">{item.subTitle}</p>
	// 													<Typography>
	// 														<div dangerouslySetInnerHTML={{__html: item.content}} />
	// 													</Typography>
	// 													<div>{backButtonDetails}</div>
	// 												</Route>
	// 											)
	// 										})
	// 									})}
	// 									<Route exact path="/privacy-manager/imprint/">
	// 										<div className="mb-2">{backButton}</div>
	// 										<Typography>
	// 											<div dangerouslySetInnerHTML={{__html: contentImprint}} />
	// 										</Typography>
	// 										<div>{backButton}</div>
	// 									</Route>
	// 									<Route exact path="/privacy-manager/privacy-policy/">
	// 										<div className="mb-2">{backButton}</div>
	// 										<Typography>
	// 											<div dangerouslySetInnerHTML={{__html: contentPrivacyPolicy}} />
	// 										</Typography>
	// 										<div>
	// 											<Link to="/privacy-manager/details/" rel="nofollow" className="button-green-light-outline">Cookies & Dienste Einstellungen</Link>
	// 											{" "}
	// 											{backButton}
	// 										</div>
	// 									</Route>
	// 								</Switch>
	// 							</div>
	// 						</div>
	// 					)
	// 				})()}
	// 			</Router>
	// 		</div> : null}
	// 	</>
	// )
}

export function PrivacyManagerRouteListener({children}) {
	const location = useLocation()
	React.useEffect(() => {
		window.PrivacyManager.processPageChange()
	}, [JSON.stringify(location.current)])
	return <>{children}</>
}

function PrivacyManager() {
	this.processedScripts = []
	this.allowedFeatures = {}
	this.featureCallbacks = {}
	this.processedTags = []
	this.pageChangeCallbacks = []

	const processQueue = () => {
		for (let featureName in this.featureCallbacks) {
			if (this.allowedFeatures[featureName] === true && this.featureCallbacks.hasOwnProperty(featureName) && this.featureCallbacks[featureName].length) {
				this.featureCallbacks[featureName].forEach(callback => callback())
				this.featureCallbacks[featureName] = []
			}
		}
	}

	this.allowFeature = (featureName) => {
		if (!this.allowedFeatures[featureName]) {
			// console.log('allowFeature', featureName)
			this.allowedFeatures[featureName] = true
			processQueue()
		}
	}

	this.processFeatures = (allowedFeatures) => {
		// console.log('processFeatures', allowedFeatures)
		allowedFeatures.tags.forEach(({id, location, html}) => {
			if (!this.processedTags.includes(id)) {
				switch (location) {
					case "head":
						document.head.insertAdjacentHTML("beforeend", html)
						break
					case "body-top":
						document.body.insertAdjacentHTML("afterbegin", html)
						break
					case "body-bottom":
						document.body.insertAdjacentHTML("beforeend", html)
						break
				}
				this.processedTags.push(id)
			}
		})
		allowedFeatures.scripts.forEach(({id, location, code}) => {
			if (!this.processedScripts.includes(id)) {
				try {
					const callback = Function("\"use strict\";" + code)
					switch (location) {
						case "init":
							callback()
							break
						case "page-change":
							this.pageChangeCallbacks.push(callback)
							break
					}
				} catch (e) {
					console.log(e)
				}
				this.processedScripts.push(id)
			}
		})
		allowedFeatures.features.forEach(featureName => this.allowFeature(featureName))
	}

	this.processPageChange = () => {
		this.pageChangeCallbacks.forEach(callback => callback())
	}

	this.onFeatureReady = (featureName, callback) => {
		if (this.featureCallbacks[featureName] === undefined) {
			this.featureCallbacks[featureName] = []
		}
		this.featureCallbacks[featureName].push(callback)
		processQueue()
	}
}

window.PrivacyManager = new PrivacyManager()
