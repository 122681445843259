import React from "react"
import {Link} from "react-location"
import styles from "./LinkComponent.module.scss"
import classNames from "classnames"
import $ from "jquery"

export function LinkComponent({link = {}, buttonStyle = "link", size, children, className = "", iconLeft, iconRight, ...props}) {
	//{type: linkType, href, size = "base", onClick, title, newTab, className, children, ...props}
	if (!(link.type || buttonStyle === "scroll-down")) {
		return null
	}
	className = classNames(className, styles.link, size ? `text-${size}` : null, `button-${buttonStyle}`)
	const refElement = React.useRef()
	const scrollDownOnClick = React.useCallback((e) => {
		e.preventDefault()
		if (refElement.current) {
			const section = refElement.current.closest("section.section")
			if (section && section.nextElementSibling) {
				section.nextElementSibling.scrollIntoView({behavior: "smooth", block: "start"})
			}
		}
		// const element = document.getElementsByClassName("page-builder-sections")[0]
		// if (element && element.children.length > 1) {
		// 	element.children[1]
		// }
	}, [])
	const videoOnClick = React.useCallback((e) => {
		e.preventDefault()
		$.magnificPopup.open({
			items: {
				src: e.currentTarget.href,
			},
			type: "iframe",
		})
	}, [])
	let iconClassName = "h-6 w-6"
	if (buttonStyle === "scroll-down") {
		link.onClick = scrollDownOnClick
		link.href = ""
		iconLeft = "arrowDown"
		if (!children || !React.Children.count(children)) {
			iconClassName = "h-24 w-6"
		}
	}
	if (buttonStyle === "video") {
		link.onClick = videoOnClick
		iconLeft = "video"
	}
	if (buttonStyle === "long-arrow-left") {
		link.onClick = videoOnClick
		iconLeft = "longArrowLeft"
	}
	if (buttonStyle === "long-arrow-right") {
		link.onClick = videoOnClick
		iconRight = "longArrowRight"
	}
	const icons = {
		chevronDown: <svg xmlns="http://www.w3.org/2000/svg" className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
		</svg>,
		arrowDown: <svg xmlns="http://www.w3.org/2000/svg" className={iconClassName} viewBox="0 0 18.801 61.104">
			<path d="M51.7,0,49.994,1.709,56.465,8.18H0v2.442H56.465l-6.471,6.471L51.7,18.8l9.4-9.4Z" transform="translate(18.801) rotate(90)" fill="currentColor" />
		</svg>,
		// chevronRight: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
		// 	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M9 5l7 7-7 7" />
		// </svg>,
		video: <svg xmlns="http://www.w3.org/2000/svg" className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
		</svg>,
		longArrowLeft: <svg xmlns="http://www.w3.org/2000/svg" className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
		</svg>,
		longArrowRight: <svg xmlns="http://www.w3.org/2000/svg" className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
		</svg>,
	}
	const body = <>
		{typeof iconLeft === "string" && icons[iconLeft] ? <span className={classNames(styles.icon, styles.left)}>
			{icons[iconLeft]}
		</span> : null}
		{typeof iconLeft !== "string" && iconLeft ? iconLeft : null}
		{children ? <span>{children}</span> : null}
		{typeof iconRight === "string" && icons[iconRight] ? <span className={classNames(styles.icon, styles.right)}>
			{icons[iconRight]}
		</span> : null}
		{typeof iconRight !== "string" && iconRight ? iconRight : null}
	</>
	if (link.type === "Internal" && !link.target) {
		return <Link _ref={refElement} className={className} to={link.href} {...props} title={link.title}>{body}</Link>
	}
	return <a ref={refElement} className={className} href={link.href} target={link.target} onClick={link.onClick} title={link.title} {...props}>{body}</a>
}
