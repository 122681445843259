import React from "react"
import {ContentElementWrapper} from "../layout"
import $ from "jquery"
import classNames from "classnames"

export function Images({nodeType, items, showPopup}) {
	const onClick = React.useCallback((e) => {
		e.preventDefault()
		$.magnificPopup.open({
			items: items.map((item, i) => ({
				src: item.image.url,
			})),
			gallery: {
				enabled: true,
			},
			type: "image",
		}, parseInt(e.currentTarget.dataset.galleryindex))
	}, [])
	return (
		<ContentElementWrapper {...{nodeType}}>
			<div className="grid gap-2 md:gap-default grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
				{items.map((item, i) => (
					<div key={`${i}${item.image.url}`} className={classNames({
						"col-span-2 md:col-span-3 lg:col-span-4": i === 0,
					})}>
						{showPopup ? <>
							<a onClick={onClick} target="_blank" data-galleryindex={i} href={item.image.url}>
								<img src={item.image.url} alt="" className="w-full" />
							</a>
						</> : <>
							<img src={item.image.url} alt="" className="w-full" />
						</>}
					</div>
				))}
			</div>
		</ContentElementWrapper>
	)
}
