import React from "react"
import {buildPageBuilderTree} from "../utility"
import classNames from "classnames"

export function PageBuilderContent({content, initialNodeType = "root"}) {
	if (content && content.ROOT) {
		const root = buildPageBuilderTree(content, "ROOT", initialNodeType)
		return (
			<>
				{root.children}
			</>
		)
	}
	return null
}
