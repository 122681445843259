import React from "react"
import {PageBuilderContentContainer} from "../components"
import { PageLayout, Section} from "../layout"
import {FaqQuestionList} from "../components/FaqQuestionList"

export function FaqCategory(route) {
	const {sidebarContent, questions, metaTitle, absoluteLink, metaDescription} = route.faqCategory
	return (
		<PageLayout metaTitle={metaTitle} absoluteLink={absoluteLink} metaDescription={metaDescription}>
			<Section>
				<div className="flex flex-col md:flex-row gap-default">
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4">
						<PageBuilderContentContainer content={sidebarContent} isTopLevel={false} />
					</div>
					<div className="md:w-1/2 lg:w-2/3 xl:w-3/4">
						<FaqQuestionList questions={questions} />
						{/*<div className="bg-green-dark h-96"></div>*/}
					</div>
				</div>
			</Section>
		</PageLayout>
	)
}
