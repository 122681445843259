import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./styles/layout.scss"
import "./styles/typography.scss"
import "./styles/buttons.scss"

import $ from "jquery";
import "magnific-popup/dist/magnific-popup.css"
import "magnific-popup/dist/jquery.magnific-popup.min"

import "swiper/scss"
import "swiper/scss/navigation"

import SwiperCore, {Navigation} from "swiper"
SwiperCore.use([Navigation])

const appElement = document.getElementById("app")
ReactDOM.render(<App {...window.appData} />, appElement)
// if (appElement) {
// 	fetch("/frontend/v1/content")
// 		.then(response => response.json())
// 		.then(appData => {
// 			ReactDOM.render(<App {...appData} />, appElement)
// 		})
// }


