// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkComponent-module__link___zG2V2 .LinkComponent-module__icon___rCix8 {\n  vertical-align: middle;\n  display: inline-block; }\n  .LinkComponent-module__link___zG2V2 .LinkComponent-module__icon___rCix8.LinkComponent-module__right___hRFPU {\n    margin: 0 0 0 3px; }\n  .LinkComponent-module__link___zG2V2 .LinkComponent-module__icon___rCix8.LinkComponent-module__left___zug8m {\n    margin: 0 3px 0 0; }\n", "",{"version":3,"sources":["webpack://./src/components/LinkComponent.module.scss"],"names":[],"mappings":"AAAA;EAqBE,sBAAsB;EACtB,qBAAqB,EAAA;EAtBvB;IA2BG,iBAAiB,EAAA;EA3BpB;IA+BG,iBAAiB,EAAA","sourcesContent":[".link {\n\t//@apply text-pink;\n\t//@apply border-pink;\n\t//@apply pb-2;\n\t//@apply uppercase;\n\t//@apply font-bold;\n\t//border-bottom: 3px solid;\n\t//vertical-align: middle;\n\t//cursor: pointer;\n\n\t//.style-default {}\n\t//.style-video {}\n\t//.style-dark-green-outline {}\n\t//.style-light-green-full {}\n\t//.style-light-green-outline {}\n\t//.style-white-full {}\n\t//.style-white-outline {}\n\t//.style-link {}\n\n\n\t.icon {\n\t\tvertical-align: middle;\n\t\tdisplay: inline-block;\n\t\t//width: 16px;\n\t\t//height: 20px;\n\n\t\t&.right {\n\t\t\tmargin: 0 0 0 3px;\n\t\t}\n\n\t\t&.left {\n\t\t\tmargin: 0 3px 0 0;\n\t\t}\n\n\t\tsvg {\n\t\t\t//height: 18px;\n\t\t\t//margin: 0 0 0 -5px;\n\t\t}\n\t}\n}\n\n//:global(.text-white) .link {\n//\tcolor: white;\n//\tborder-color: white;\n//}\n//\n//\n//:global(.text-sm) {\n//\t&.link {\n//\t\t@apply pb-1;\n//\t}\n//}\n//\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "LinkComponent-module__link___zG2V2",
	"icon": "LinkComponent-module__icon___rCix8",
	"right": "LinkComponent-module__right___hRFPU",
	"left": "LinkComponent-module__left___zug8m"
};
export default ___CSS_LOADER_EXPORT___;
