import React from "react"
import {ContentElementWrapper, Section} from "../layout"
import Icon from "@mdi/react"
import {mdiInstagram} from "@mdi/js"
import {Swiper, SwiperSlide} from "swiper/react"
import {AppStateContext} from "../state"

export function InstagramTeaser({nodeType, profileLink, posts}) {
	const {containerMargin, bodyWidth} = React.useContext(AppStateContext)
	const gap = 10
	let imageWidth = 0
	if (bodyWidth > 800) {
		imageWidth = (bodyWidth - gap * 3) / 4
	} else if (bodyWidth > 600) {
		imageWidth = (bodyWidth - gap * 2) / 3
	} else if (bodyWidth > 400) {
		imageWidth = (bodyWidth - gap) / 2
	} else {
		imageWidth = bodyWidth
	}
	return (
		<ContentElementWrapper {...{nodeType}} background={"grey"} containerWidth="none">
			<Section as="div" className="!pt-0 !pb-default" containerWidth="wide" innerClassName="!py-0" containerProps={{
				// elementRef: refContainer,
				// className: "",
				classNameInner: "flex gap-default flex-col xs:flex-row xs:justify-between xs:items-end",
			}}>
				<h3 className="is-h5 !mb-0">Community</h3>
				<a target="_blank" href={profileLink} className="flex items-center">
					<Icon path={mdiInstagram} className="w-6 h-6 mr-2" />
					<span>Folge uns auf Insta</span>
				</a>
			</Section>
			{/*offsetLeft*/}
			<Swiper slidesOffsetBefore={containerMargin} slidesOffsetAfter={containerMargin} slidesPerView={"auto"} spaceBetween={gap} centeredSlides={false} loop={false} navigation={false}>
				{posts.map(post => {
					return (
						<SwiperSlide key={post.id} style={{width: imageWidth, maxWidth: "100vw"}}>
							<a href={post.link} target="_blank" title={post.caption}>
								<img src={post.thumbnailDataUri} alt="" className="w-full rounded-default mb-2" />
								<b>{post.hashTags?.[0]}</b>
							</a>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</ContentElementWrapper>
	)
}
