import React from "react"
import {Dialog, Transition} from "@headlessui/react"
import {XIcon} from "@heroicons/react/outline"
import {AppStateContext} from "../state"

const products = [
	{
		id: 1,
		name: "Test",
		href: "#",
		price: "€ 90,00",
		quantity: 1,
		imageSrc: "/assets/Uploads/1.jpg",
		imageAlt: "",
	},
	// {
	// 	id: 2,
	// 	name: "Medium Stuff Satchel",
	// 	href: "#",
	// 	color: "Blue",
	// 	price: "$32.00",
	// 	quantity: 1,
	// 	imageSrc: "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-02.jpg",
	// 	imageAlt:
	// 		"Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.",
	// },
	// // More products...
]

export function CartOverlay() {
	const {showCartOverlay: open, setShowCartOverlay: setOpen} = React.useContext(AppStateContext)
	return (
		<Transition.Root show={open} as={React.Fragment}>
			<Dialog as="div" className="fixed inset-0 overflow-hidden" style={{zIndex: 150}} onClose={setOpen}>
				<div className="absolute inset-0 overflow-hidden">
					<Transition.Child
						as={React.Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
						<Transition.Child
							as={React.Fragment}
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enterFrom="translate-x-full"
							enterTo="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leaveFrom="translate-x-0"
							leaveTo="translate-x-full"
						>
							<div className="w-screen max-w-md">
								<div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
									<div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
										<div className="flex items-start justify-between">
											<Dialog.Title className="text-lg">Warenkorb</Dialog.Title>
											<div className="ml-3 h-7 flex items-center">
												<button type="button" className="-m-2 p-2" onClick={() => setOpen(false)}>
													{/*<span className="sr-only">Close panel</span>*/}
													<XIcon className="h-6 w-6" aria-hidden="true" />
												</button>
											</div>
										</div>

										<div className="mt-8">
											<div className="flow-root">
												<ul role="list" className="-my-6 divide-y divide-grey-light">
													{products.map((product) => (
														<li key={product.id} className="py-6 flex">
															<div className="flex-shrink-0 w-24 h-24 rounded overflow-hidden">
																<img src={product.imageSrc} alt={product.imageAlt} className="w-full h-full object-center object-cover" />
															</div>

															<div className="ml-4 flex-1 flex flex-col">
																<div>
																	<h3 className="font-bold">
																		<a href={product.href}>{product.name}</a>
																	</h3>
																	<p className="mt-1">{product.price}</p>
																</div>
																<div className="flex-1 flex items-end justify-between">
																	<p className="">
																		<a href="#" className="inline-block pr-2">-</a>
																		{product.quantity}
																		<a href="#" className="inline-block pl-2">+</a>
																	</p>

																	<div className="flex">
																		<button type="button" className="font-medium text-indigo-600 hover:text-indigo-500">
																			Entfernen
																		</button>
																	</div>
																</div>
															</div>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>

									<div className="py-6 px-4 sm:px-6">
										<p className="text-grey">inkl. MwSt. / exkl. Versandkosten</p>
										<div className="flex justify-between mb-default/2">
											<p>Zwischensumme</p>
											<p>€ 90,00</p>
										</div>
										<div className="flex justify-between">
											{/*<button type="button" className="button-black-outline" onClick={() => setOpen(false)}>*/}
											{/*	Weiter einkaufen*/}
											{/*</button>*/}
											<a href="#" className="button-black-full w-full">Zur Kassa</a>
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
