import React from "react"
import {useUniqueId} from "../../utility"
import {FormFieldWrapper} from "./FormFieldWrapper"
import {Field, useField} from "formik"

export function FormTextField({
	                              name,
	                              label,
	                              labelRight,
	                              type = "text",
	                              id,
	                              placeholder,
	                              description,
	                              borderColor = "grey-light",
	                              inputClass = "text-black placeholder:text-grey focus:border-green",
	                              disabled,
	                              required,
	                              style,
                              }) {
	id = useUniqueId(id)
	const [, {touched, error}] = useField(name)
	if (touched && error) {
		borderColor = `notification-${error.color}`
	}
	return (
		<FormFieldWrapper {...{name, label, labelRight, id, description}}>
			<Field
				name={name}
				type={type}
				id={id}
				className={`block w-full sm:text-sm border-2 rounded focus:ring-0 border-${borderColor} ${inputClass}`}
				placeholder={placeholder}
				disabled={disabled}
				required={required}
			/>
		</FormFieldWrapper>
	)
}
