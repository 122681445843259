import React from "react"
import {Disclosure, Transition} from "@headlessui/react"
import {PlusIcon, MinusIcon} from "@heroicons/react/solid"
import {PageBuilderContentContainer} from "./PageBuilderContentContainer"

export function FaqQuestionList({questions}) {
	return (
		<div>
			{questions.map(question => <FaqQuestion {...question} key={question.id} />)}
		</div>
	)
}

function FaqQuestion({id, title, content}) {
	if (!content) {
		return null
	}
	return (
		<div>
			<Disclosure>
				{({open}) => (
					<>
						<Disclosure.Button as="a" className="block py-4 border-b-2 border-black is-h6 flex justify-between cursor-pointer">
							<span className="pr-default">{title}</span>
							<span>{open ? <MinusIcon className={"h-6"} /> : <PlusIcon className={"h-6"} />}</span>
						</Disclosure.Button>
						{/* Use the Transition + open render prop argument to add transitions. */}
						<Transition
							show={open}
							enter="transition duration-100 ease-out"
							enterFrom="transform scale-95 opacity-0"
							enterTo="transform scale-100 opacity-100"
							leave="transition duration-75 ease-out"
							leaveFrom="transform scale-100 opacity-100"
							leaveTo="transform scale-95 opacity-0"
						>
							<Disclosure.Panel static>
								<div className="pb-default">
									<PageBuilderContentContainer content={content} isTopLevel={false} />
								</div>
							</Disclosure.Panel>
						</Transition>
					</>
				)}
			</Disclosure>
		</div>
	)
}
