import React from "react"
import {Swiper} from "swiper/react"

export function SwiperWithArrows({onBeforeInit, ...props}) {
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
	return (
		<div className="relative">
			<Swiper
				{...props}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={swiper => {
					typeof onBeforeInit === "function" && onBeforeInit()
					swiper.params.navigation.prevEl = navigationPrevRef.current
					swiper.params.navigation.nextEl = navigationNextRef.current
				}}
			/>
			<button className="absolute z-10 cursor-pointer top-1/2 w-16 h-16 -mt-8 flex justify-center items-center text-white left-6 disabled:opacity-60" ref={navigationPrevRef}>
				<svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
				</svg>
			</button>
			<button className="absolute z-10 cursor-pointer top-1/2 w-16 h-16 -mt-8 flex justify-center items-center text-white right-6 disabled:opacity-60" ref={navigationNextRef}>
				<svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
				</svg>
			</button>
		</div>
	)
}
