import React from "react"
import classNames from "classnames"
import styles from "./Container.module.scss"
import {ContentElementWrapper} from "../layout"

export function Container({children, nodeType, background, columns, containerWidth, verticalAlignment}) {
	return (
		<ContentElementWrapper containerWidth={containerWidth} {...{
			nodeType,
			background,
			className: classNames(styles.containerContentElement, styles[`columns-${columns}`]),
		}}>
			<div className={classNames(styles.containerContentElementInner, {
				"items-start": verticalAlignment === "top",
				"items-center": verticalAlignment === "center",
				"items-end": verticalAlignment === "bottom",
				"items-stretch": verticalAlignment === "stretch",
			})}>
				{children}
			</div>
		</ContentElementWrapper>
	)
}
