import React from "react"
import redraft from "redraft"
import {Link} from "react-location"
import {Typography} from "../components"
import {ContentElementWrapper} from "../layout"

const addBreaklines = (children, keys) => children.map((child, i) => <React.Fragment key={keys[i]}>{child}<br /></React.Fragment>)

const DraftComponentLinebreak = ({children, key}) => <React.Fragment key={key}><br />{children}</React.Fragment>

function DraftComponentLink({children, data: {linkType, ...props}}) {
	if (linkType === "Internal") {
		props.to = props.href
		props.href = undefined
		return <Link {...props}>{children}</Link>
	}
	return <a {...props}>{children}</a>
}

/**
 * Define the renderers
 */
const renderers = {
	/**
	 * Those callbacks will be called recursively to render a nested structure
	 */
	inline: {
		// The key passed here is just an index based on rendering order inside a block
		BOLD: (children, {key}) => <strong key={key}>{children}</strong>,
		ITALIC: (children, {key}) => <em key={key}>{children}</em>,
		UNDERLINE: (children, {key}) => <u key={key}>{children}</u>,
		// CODE: (children, {key}) => <span key={key}>{children}</span>,// TODO code style
		STRIKETHROUGH: (children, {key}) => <span key={key} style={{textDecoration: "line-through"}}>{children}</span>,
		HIGHLIGHT: (children, {key}) => <span key={key} className="text-primary">{children}</span>,
	},
	/**
	 * Blocks receive children and depth
	 * Note that children are an array of blocks with same styling,
	 */
	blocks: {
		blockquote: (children, {keys}) => <blockquote key={keys.join("|")}>{addBreaklines(children, keys)}</blockquote>,
		unstyled: (children, {keys, data}) => children.map((child, i) => <p key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</p>),
		"header-one": (children, {keys, data}) => children.map((child, i) => <h1 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h1>),
		"header-two": (children, {keys, data}) => children.map((child, i) => <h2 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h2>),
		"header-three": (children, {keys, data}) => children.map((child, i) => <h3 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h3>),
		"header-four": (children, {keys, data}) => children.map((child, i) => <h4 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h4>),
		"header-five": (children, {keys, data}) => children.map((child, i) => <h5 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h5>),
		"header-six": (children, {keys, data}) => children.map((child, i) => <h6 key={keys[i]} className={data?.[i]?.textAlignment ? `text-${data?.[i]?.textAlignment}`.toLowerCase() : ""}>{child}</h6>),
		// You can also access the original keys of the blocks
		"code-block": (children, {keys}) => <pre key={keys.join("|")}>{addBreaklines(children, keys)}</pre>,// TODO code-block style
		// or depth for nested lists
		"unordered-list-item": (children, {depth, keys}) => <ul key={keys.join("|")} className={`ul-level-${depth}`}>{children.map((child, i) => <li key={keys[i]}>{child}</li>)}</ul>,
		"ordered-list-item": (children, {depth, keys}) => <ol key={keys.join("|")} className={`ol-level-${depth}`}>{children.map((child, i) => <li key={keys[i]}>{child}</li>)}</ol>,
		// // If your blocks use meta data it can also be accessed like keys
		// atomic: (children, {keys, data}) => children.map((child, i) => <Atomic key={keys[i]} {...data[i]} />),
	},

	/**
	 * Entities receive children and the entity data
	 */
	entities: {
		// key is the entity key value from raw
		// LINK: (children, data, {key}) => <Link key={key} to={data.url}>{children}</Link>,
		LINK: (children, data, {key}) => {
			return <DraftComponentLink key={key} data={data}>{children}</DraftComponentLink>
		},
	},

	/**
	 * Array of decorators,
	 * Entities receive children and the entity data,
	 * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
	 * it's also possible to pass a custom Decorator class that matches the [DraftDecoratorType](https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js)
	 */
	decorators: [
		{
			strategy: (contentBlock, callback) => {
				const text = contentBlock.get("text")
				const matches = [...text.matchAll(/\n/g)].map(a => a.index)
				matches.forEach(i => {
					callback(i, i + 2)
				})
			},
			component: DraftComponentLinebreak,
		},
	],
}

export function DraftEditor({content, nodeType, ...props}) {
	if (content) {
		const rendered = redraft(content, renderers)
		if (rendered) {
			return (
				<ContentElementWrapper {...{nodeType}}>
					<Typography>{rendered}</Typography>
				</ContentElementWrapper>
			)
		}
	}
	return null
}
