import React from "react"
import * as contentElements from "../contentElements"

export function buildPageBuilderTree(nodes, id = "ROOT", nodeType = "root") {
	const node = nodes[id]
	const childNodes = node.nodes ? node.nodes.map((_id) => buildPageBuilderTree(nodes, _id, "child")) : []
	const linkedNodes = node.linkedNodes ? Object.fromEntries(Object.entries(node.linkedNodes).map(([linkId, _id]) => [linkId, buildPageBuilderTree(nodes, _id, "linked")])) : {}
	const treeNode = {
		id,
		type: node.type || node.componentKey.replace(/^.*\\(.*?)\.Default$/, "$1"),
		props: node.props,
		childNodes,
		linkedNodes,
	}
	treeNode.children = childNodes.map(({id: key, type, childNodes, linkedNodes, children, props}) => {
		if (contentElements[type] === undefined) {
			return <div key={key} style={{border: "1px solid red", width: "100%", overflow: "auto", padding: 20}}>
				<h1>{`Unknown content element "${type}"`}</h1>
				<pre>{JSON.stringify({id: key, type, ...props}, null, 2)}</pre>
			</div>
			// throw `Unknown content element "${type}"`
		}
		//parentNode: treeNode,
		//, isTopLevel: id === "ROOT"
		return React.createElement(contentElements[type], {key, id: key, linkedNodes, children, nodeType, ...props})
	})
	return treeNode
}
