import React from "react"
import {ContentElementWrapper} from "../layout"
import {LinkComponent, Typography} from "../components"

export function ProductHighlightTeaser({nodeType, product}) {
	return (
		<ContentElementWrapper {...{nodeType}}>
			<div className="grid gap-default grid-cols-1 md:grid-cols-2 items-center">
				<div>
					<img src={product.teaserImage.url} alt="" />
				</div>
				<div>
					<div className="pb-default">
						<Typography>
							<h3 className="!mb-0">{product.teaserTitle}</h3>
							<p className="pt-2">
								<b>{product.priceGrossNice}</b>{" "}
								<span className="text-grey">inkl. MwSt. / exkl. Versandkosten</span>
							</p>
							<p dangerouslySetInnerHTML={{__html: product.teaserContent}} />
						</Typography>
					</div>
					<LinkComponent buttonStyle="green-full" link={{type: "Internal", href: product.link}}>zum Produkt</LinkComponent>
				</div>
			</div>
		</ContentElementWrapper>
	)
}
