// import React from "react"
//
// export function TeaserDefault({children, background = "green-light", className = "", ...props}) {
// 	return (
// 		<div className={`background-${background} rounded-default p-default flex-1 ${className}`}>
// 			{children}
// 		</div>
// 	)
// }

import React from "react"
import {AppStateContext} from "../../state"

export function TeaserDefault({children, teaserImage, teaserType, className = "", style = {}, ...props}) {
	const {containerMargin} = React.useContext(AppStateContext)
	const [touchingViewportEdge, setTouchingViewportEdge] = React.useState("")
	const ref = React.useRef()
	React.useEffect(() => {
		const resizeHandler = () => {
			if (ref.current) {
				if (ref.current.offsetLeft === 0 && touchingViewportEdge !== "left") {
					setTouchingViewportEdge("left")
				} else if (ref.current.offsetLeft > 0 && touchingViewportEdge === "left") {
					setTouchingViewportEdge("")
				}
			}
		}
		window.addEventListener("resize", resizeHandler)
		resizeHandler()
		setTimeout(resizeHandler, 100)
		setTimeout(resizeHandler, 300)
		setTimeout(resizeHandler, 1000)
		setTimeout(resizeHandler, 5000)
		return () => window.removeEventListener("resize", resizeHandler)
	}, [ref.current, touchingViewportEdge])
	const paddingLeft = touchingViewportEdge === "left" ? containerMargin : undefined
	return (
		<div ref={ref} className={`flex-1 relative text-white overflow-hidden flex ${className}`} style={{minHeight: 350, ...style}}>
			{["BackgroundImageShadow", "BackgroundImageFull"].includes(teaserType) && teaserImage?.url ?
				<img className="absolute inset-0 h-full w-full object-cover" src={teaserImage.url} alt="" /> : null}
			{teaserType === "BackgroundImageRight" && teaserImage?.url ? <img className="absolute left-1/2 top-1/3 h-full w-full object-contain" src={teaserImage.url} alt="" /> : null}
			{teaserType === "BackgroundImageShadow" && teaserImage?.url ? <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black opacity-80 h-24 lg:h-32" /> : null}
			<div className="relative flex-1 flex flex-col justify-between p-default" style={{paddingLeft}}>
				{children}
			</div>
			{/*<div className="absolute bottom-0 inset-x-0 p-default text-white overflow-hidden">*/}
			{/*	{children}*/}
			{/*</div>*/}
		</div>
	)
}

