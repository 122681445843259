import React from "react"
import classNames from "classnames"
import {PageBuilderContent} from "./PageBuilderContent"

export function PageBuilderContentContainer({content, isTopLevel = true, className = ""}) {
	const initialNodeType = isTopLevel ? "root" : "child"
	if (content && content.ROOT) {
		return (
			<div className={classNames(className, isTopLevel ? "page-builder-sections" : "child-content-element-container")}>
				<PageBuilderContent content={content} initialNodeType={initialNodeType} />
			</div>
		)
	}
	return null
}
