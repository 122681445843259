// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListElement-module__list___gCOtj {\n  list-style: square outside;\n  padding: 0 0 0 25px; }\n", "",{"version":3,"sources":["webpack://./src/contentElements/ListElement.module.scss"],"names":[],"mappings":"AAAA;EAEC,0BAA0B;EAC1B,mBAAmB,EAAA","sourcesContent":[".list {\n\t//list-style: outside url(\"http://127.0.0.1:3000/images/list-checkbox.svg\");\n\tlist-style: square outside;\n\tpadding: 0 0 0 25px;\n\n\tli {\n\t\t//display: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ListElement-module__list___gCOtj"
};
export default ___CSS_LOADER_EXPORT___;
