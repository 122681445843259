import React from "react"

export function Typography({children}) {
	if (!React.Children.count(children)) {
		return null
	}
	return (
		<div className="typography">
			{children}
		</div>
	)
}
