import React from "react"
import {nanoid} from "nanoid"

export function useUniqueId(existingId) {
	const [id, setId] = React.useState("")
	React.useEffect(() => {
		setId(`unique-id-${nanoid()}`)
	}, [])
	return existingId || id
}
