import React from "react"
import classNames from "classnames"
import {Container} from "./Container"

//useContainer = true,
// const ContainerComponent = useContainer ? Container : React.Fragment;
export function Section({as = "section", children, elementRef, containerWidth = "wide", className = "", innerClassName = "", containerProps = {}, ...props}) {
	return React.createElement(as, {
		...props,
		ref: elementRef,
		className: classNames("section", className, `section-max-width-${containerWidth}`),
		children: (
			<>
				<div className={classNames("section-inner", innerClassName)}>
					<Container {...containerProps} containerWidth={containerWidth}>
						{children}
					</Container>
				</div>
			</>
		),
	})
}
