import React from "react"

export * from "./AppStateProvider"
export * from "./query"

export const AppStateContext = React.createContext()

// export function useGlobalContent() {
// 	return initialValues.globals
// }
//
// export function useBaseUrl() {
// 	return initialValues.baseUrl
// }
//
export function useSiteConfig() {
	const {
		siteConfig,
	} = React.useContext(AppStateContext)
	return {
		siteConfig,
	}
}

// export function usePages() {
// 	const {
// 		pages,
// 	} = React.useContext(AppStateContext)
// 	return {
// 		pages,
// 		pageById: React.useCallback((pageId) => {
// 			return pages.find(({id}) => id === pageId)
// 		}, []),
// 	}
// }

// export function useAppState() {
// 	const {
// 		globalNoticeDismissed,
// 		setGlobalNoticeDismissed,
// 		mapReady,
// 		setMapReady,
// 	} = React.useContext(AppStateContext)
// 	return {
// 		globalNoticeDismissed,
// 		setGlobalNoticeDismissed,
// 		mapReady,
// 		setMapReady,
// 	}
// }
