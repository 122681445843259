import React from "react"
import {AppStateContext} from "./index"

export function AppStateProvider({children, ...initialValues}) {
	// const [pages] = React.useState(initialValues.pages)
	// const pages = initialValues.pages
	// const [siteConfig] = React.useState(initialValues.siteConfig)
	const siteConfig = initialValues.siteConfig
	const errorPages = initialValues.errorPages
	// const [currentPage, setCurrentPage] = React.useState()
	const [currentRoute, setCurrentRoute] = React.useState()
	const [privacyManagerState, setPrivacyManagerState] = React.useState(() => JSON.parse(JSON.stringify(initialValues.privacyManager.state)))
	const [privacyManagerOpen, setPrivacyManagerOpen] = React.useState(!initialValues.privacyManager.state.type)
	const [containerMargin, setContainerMargin] = React.useState(0)
	const [bodyWidth, setBodyWidth] = React.useState(0)
	const [showCartOverlay, setShowCartOverlay] = React.useState(false)
	const refMeasurementContainer = React.useRef()
	React.useEffect(() => {
		const resizeHandler = () => {
			if (refMeasurementContainer.current) {
				setContainerMargin(refMeasurementContainer.current.offsetLeft)
				setBodyWidth(refMeasurementContainer.current.offsetWidth)
			} else {
				setContainerMargin(0)
				setBodyWidth(400)
			}
		}
		window.addEventListener("resize", resizeHandler)
		resizeHandler()
		setTimeout(resizeHandler, 100)
		setTimeout(resizeHandler, 300)
		setTimeout(resizeHandler, 1000)
		setTimeout(resizeHandler, 5000)
		return () => window.removeEventListener("resize", resizeHandler)
	}, [refMeasurementContainer.current])
	return (
		<AppStateContext.Provider value={{
			// pages,
			siteConfig,
			errorPages,
			currentRoute,
			setCurrentRoute,
			privacyManagerState,
			setPrivacyManagerState,
			privacyManagerOpen,
			setPrivacyManagerOpen,
			containerMargin,
			bodyWidth,
			showCartOverlay,
			setShowCartOverlay,
		}}>
			{children}
			<div className="section !py-0 section-max-width-wide absolute opacity-0 top-0 inset-x-0" style={{zIndex: -1}}>
				<div className="section-inner !py-0">
					<div className="layout-container layout-container-max-width-wide" ref={refMeasurementContainer} style={{height: 1}} />
				</div>
			</div>
		</AppStateContext.Provider>
	)
}
