import React from "react"
import {ContentElementWrapper} from "../layout"
import {Typography} from "../components"
import {FormikForm, FormTextField, SubmitButton} from "../components/form"

export function NewsletterTeaser({nodeType, htmlContent}) {
	return (
		<ContentElementWrapper {...{nodeType}} background={"grey"}>
			<div className="max-w-xl mx-auto">
				<div className="pb-default">
					<Typography>
						<div dangerouslySetInnerHTML={{__html: htmlContent}} />
					</Typography>
				</div>
				<FormikForm initialValues={{email: ""}} className="flex gap-default/2">
					<div className="flex-1">
						<FormTextField name="email" placeholder="Deine E-Mail Adresse" borderColor="grey" inputClass="bg-transparent text-black placeholder:text-black focus:border-black" />
					</div>
					<div className="flex-0">
						<SubmitButton buttonStyle="black-full" className="uppercase mb-default md:pr-6 md:pl-6">Anmelden</SubmitButton>
					</div>
				</FormikForm>
			</div>
		</ContentElementWrapper>
	)
}
