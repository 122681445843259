import React from "react"
import {PageBuilderContentContainer, Typography} from "../components"
import {ContentElementWrapper, PageLayout, Section} from "../layout"
import {AppStateContext} from "../state"
import {SwiperSlide} from "swiper/react"
import {SwiperWithArrows} from "../components/SwiperWithArrows"
import Icon from "@mdi/react"
import {mdiCheckCircle} from "@mdi/js"
import {TeaserPortrait} from "../components/teaser"
import {Link} from "react-location"

export function Product(route) {
	const {containerMargin, setShowCartOverlay} = React.useContext(AppStateContext)
	const product = route.product
	const {content, metaTitle, absoluteLink, metaDescription, relatedProducts, images, title, priceGrossNice, summaryContent} = route.product
	const addToCartOnClick = React.useCallback(() => setShowCartOverlay(true), [])
	return (
		<PageLayout metaTitle={metaTitle} absoluteLink={absoluteLink} metaDescription={metaDescription}>
			<div className="grid grid-cols-1 md:grid-cols-2 md:items-stretch">
				<div>
					<SwiperWithArrows loop={false}>
						{images.map((image, i) => {
							return (
								<SwiperSlide key={`${image.url}${i}`}>
									<div className="h-96 md:h-auto md:min-h-screen-nav">
										<img src={image.url} alt="" className="absolute inset-0 h-full w-full object-cover" />
									</div>
								</SwiperSlide>
							)
						})}
					</SwiperWithArrows>
				</div>
				<div className="flex !md:pl-0" style={{paddingRight: containerMargin, paddingLeft: containerMargin}}>
					<div className="flex-1 flex flex-col justify-between gap-default">
						<div />
						<div>
							<div className="pb-default">
								<Typography>
									<h1 className="!mb-0">{title}</h1>
									<p className="pt-2">
										<b>{priceGrossNice}</b>{" "}
										<span className="text-grey">inkl. MwSt. / exkl. Versandkosten</span>
									</p>
									<div dangerouslySetInnerHTML={{__html: summaryContent}} />
								</Typography>
							</div>
							<button className="button-black-full w-full" onClick={addToCartOnClick}>In den Warenkorb legen</button>
						</div>
						<div className="pb-default grid gap-default/2 grid-cols-1 lg:grid-cols-3">
							<span className="flex items-center"><Icon path={mdiCheckCircle} className="w-4 h-4 mr-2 text-green" /> Klimaneutraler Versand</span>
							<span className="flex items-center"><Icon path={mdiCheckCircle} className="w-4 h-4 mr-2 text-green" /> 30 Tage Rückgaberecht</span>
							<span className="flex items-center"><Icon path={mdiCheckCircle} className="w-4 h-4 mr-2 text-green" /> Kostenloser Rückversand</span>
						</div>
					</div>
				</div>
			</div>
			<Section>
				<PageBuilderContentContainer content={content} isTopLevel={false} />
			</Section>
			{relatedProducts.length ? <ContentElementWrapper nodeType="root" containerWidth="wide" className="!pt-0" innerClassName="!pt-0">
				<div className="border-t border-black pt-16">
					<h4 className="is-h4">
						<span className="text-grey">Ähnliche Produkte</span>
						<br />
						Empfehlungen für dich
					</h4>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-default">
						{relatedProducts.map(relatedProduct => {
							return (
								<Link to={relatedProduct.link}>
									<TeaserPortrait teaserImage={relatedProduct.teaserImage}>
										<h3>{relatedProduct.teaserTitle}</h3>
										<p><b>{relatedProduct.priceGrossNice}</b></p>
									</TeaserPortrait>
								</Link>
							)
						})}
					</div>
				</div>
				{/*<pre>{JSON.stringify(relatedProducts, null, 2)}</pre>*/}
			</ContentElementWrapper> : null}
		</PageLayout>
	)
}
